import React from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "gatsby";

const NotFoundPage = () => {
    return (

        <Container>
            <Row>
                <Col>
                    <div className="error-template">
                        <h1>Oooh Nein!</h1>
                        <h2>
                            404 Not Found</h2>
                        <div className="error-details">
                            Sorry, an error has occured, Requested page not found!
                </div>
                        <div className="error-actions">
                            <Button onClick={e => navigate('/')} >
                                <FontAwesomeIcon icon={faHome} size="2x"></FontAwesomeIcon> Take Me Home </Button>                        
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFoundPage
